import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql, Link } from "gatsby"
import Swiper from 'react-id-swiper'
import "react-id-swiper/lib/styles/css/swiper.css";
import $ from "jquery"
import { TweenMax, TimelineMax, Linear } from "gsap/all"
import CSSPlugin from 'gsap/CSSPlugin';
import TimelineArticleImgAnim from "../components/timelineArticleImgAnim"

const C = CSSPlugin;

const IndexPage = () => {
  const [swiper, updateSwiper] = useState(null);
  const autoplayStart = () => {
    if (swiper !== null) {
      let slideWidth = 0;
      let windowWidth = $(window).width();
      let slideDuration = $(".slide-article-list .swiper-wrapper .swiper-slide").length * 0.90;
      
      $(".slide-article-list .swiper-wrapper .swiper-slide").each(function(){
        slideWidth += $(this).outerWidth( true );
      });

      let timelineMoveLeftPosition = slideWidth - windowWidth;
      let timelineSliderLeftTl = new TimelineMax({ paused:true });
      timelineSliderLeftTl
        .addLabel("startPoint")
        .to(".slide-article-list .swiper-wrapper", slideDuration, {
          x: "-"+timelineMoveLeftPosition,
          ease: Linear.easeNone,
          onUpdate: function(){
            swiper.setTranslate(swiper.getTranslate());
          }
        });

      $(".swiper-button-prev").hover(function() {
        timelineSliderLeftTl.tweenTo("startPoint");
      }, function() {
        TweenMax.killTweensOf(timelineSliderLeftTl);
      });
    
      $(".swiper-button-next").hover(function() {
        timelineSliderLeftTl.play();
        swiper.navigation.update();
      }, function() {
        timelineSliderLeftTl.pause();
      });
    }
  };
  
  const data = useStaticQuery(graphql`
    query ArticleDetailsQuery {
      allArticledetailsJson {
        edges {
          node {
            year
            id
            year_position_with_the_timeline
            year_position_with_the_image
            image_position
            year_size
            article_slug
            article_thumb
            featuredimg
            featuredimgbig
            year_background_color
            article_title
            article_title_position
          }
        }
      }
    }
  `)
  const articleData = data.allArticledetailsJson.edges.map((edge) => {
      return <div className={`slide-article-item ${edge.node.year_position_with_the_timeline} ${edge.node.year_position_with_the_image} ${edge.node.image_position} ${edge.node.year_size}`} key={edge.node.id}>
        {edge.node.article_thumb !== "" && <div className="slide-article-img">
          <img src={edge.node.article_thumb} alt="" />
          {edge.node.article_slug !== "" && edge.node.featuredimgbig !== "" && <TimelineArticleImgAnim
            cover
            to={"/" + edge.node.article_slug}
            duration={1}
            style={{backgroundImage: `url(${edge.node.article_thumb})`}} className="slide-article-img-cover" 
            bg={`url(${edge.node.featuredimgbig}) center / cover no-repeat`}></TimelineArticleImgAnim>}
          
          {edge.node.article_slug !== "" && edge.node.featuredimgbig === "" && <TimelineArticleImgAnim
            cover
            to={"/" + edge.node.article_slug}
            duration={1}
            style={{backgroundImage: `url(${edge.node.article_thumb})`}} className="slide-article-img-cover" 
            bg={edge.node.year_background_color}></TimelineArticleImgAnim>}

          {edge.node.article_slug === "" && <div style={{backgroundImage: `url(${edge.node.article_thumb})`}} className="slide-article-img-cover"></div>}
          
          </div>}
        <div className="slide-article-year">
          {edge.node.article_slug !== "" && edge.node.article_title !== "" && <div className={"slide-article-title " + edge.node.article_title_position}><TimelineArticleImgAnim
            cover
            to={"/" + edge.node.article_slug}
            duration={1} 
            bg={`url(${edge.node.featuredimgbig}) center / cover no-repeat`}>{edge.node.article_title}</TimelineArticleImgAnim></div>}
          
          {edge.node.article_slug === "" && edge.node.article_title !== "" && <div className={"slide-article-title " + edge.node.article_title_position}>{edge.node.article_title}</div>}
          
          {edge.node.article_slug !== "" && edge.node.featuredimgbig !== "" && <TimelineArticleImgAnim
            cover
            to={"/" + edge.node.article_slug}
            duration={1} 
            bg={`url(${edge.node.featuredimgbig}) center / cover no-repeat`}>{edge.node.year}</TimelineArticleImgAnim>}
            
            {edge.node.article_slug !== "" && edge.node.featuredimgbig === "" && <TimelineArticleImgAnim
            cover
            to={"/" + edge.node.article_slug}
            duration={1}
            bg={edge.node.year_background_color}>{edge.node.year}</TimelineArticleImgAnim>}
          {/* {edge.node.article_slug !== "" && <Link to={"/" + edge.node.article_slug}>{edge.node.year}</Link>} */}
          {edge.node.article_slug === "" && <span>{edge.node.year}</span>}

          {/* {edge.node.article_slug !== "" && edge.node.article_title !== "" && edge.node.article_title_position === "below_year" && <div className={"slide-article-title " + edge.node.article_title_position}><TimelineArticleImgAnim
            cover
            to={"/" + edge.node.article_slug}
            duration={1} 
            bg={`url(${edge.node.featuredimgbig}) center / cover no-repeat`}>{edge.node.article_title}</TimelineArticleImgAnim></div>} */}
        </div>
      </div>
    }
  );
  const params = {
    spaceBetween: 40,
    slidesPerView: 'auto',
    freeMode: true,
    updateOnWindowResize: true,
    // simulateTouch: false,
    // freeModeSticky: true,
    // speed: 2000,
    // autoplay: {delay: 0, stopOnLastSlide: true},
    // autoplayDisableOnInteraction: false
    breakpoints: {
      992: {
        simulateTouch: false,
        freeModeMomentum: false,
        freeModeMomentumBounce: false,
      }
    },
    on: {
      init: function(){
        $(".slide-article-list .swiper-button-next").addClass("show-arrow");
        $(".slide-article-list .swiper-button-next").mouseenter(function(){
          if(!$(".slide-article-list .swiper-button-prev").hasClass("show-arrow")){
            $(".slide-article-list .swiper-button-prev").addClass("show-arrow");
          }
        });
        $(".slide-article-list .swiper-button-prev").mouseenter(function(){
          if(!$(".slide-article-list .swiper-button-next").hasClass("show-arrow")){
            $(".slide-article-list .swiper-button-next").addClass("show-arrow");
          }
        });
      },
      reachBeginning: function(){
        $(".slide-article-list .swiper-button-prev").removeClass("show-arrow");
      },
      reachEnd: function(){
        $(".slide-article-list .swiper-button-next").removeClass("show-arrow");
      }
    }
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev'
    // }
  }
  
  return (
    <Layout animatedHeader="animatedHeader">
      <SEO title="Home" />
      <div className="home-globe-bg"></div>
      <div className="slide-article-list" onMouseEnter={autoplayStart}>
        <Swiper {...params} getSwiper={updateSwiper}>
          {articleData}
        </Swiper>
        <div className="swiper-button-prev"><span></span></div>
        <div className="swiper-button-next"><span></span></div>
        <div className="article-timeline"></div>
      </div>
    </Layout>
  )
}

export default IndexPage
